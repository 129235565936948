import {createTheme} from "@mui/material";
import '../App.css';

export const unitoTheme = createTheme({
    typography:{
        fontFamily: 'Titillium Web'
    },
    palette:{
        primary:{
            main:'#E00226'
        }
    }
})
