import React from "react";
import {Box, Container, Typography} from "@mui/material";
import {Header} from "../components/Header";

export const Privacy = () => {
    return (
        <Box>
            <Header/>
            <Container sx={{mt: 4}}>
                <Typography variant={"body1"}>
                    Placeholder
                </Typography>
            </Container>
        </Box>
    )
}
